var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre","label-for":"input-name","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-name","name":"input-name","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback","size":"sm"},model:{value:(_vm.$v.period.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.period.name, "$model", $$v)},expression:"$v.period.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 4 caracteres.")])],1),_c('b-form-group',{staticClass:"label mt-1 mb-2 mx-0",attrs:{"label-cols":"0","label-cols-sm":"3","label":_vm.$getVisibleNames('mesh.periodtype', false, 'Régimen'),"label-for":"input-regime"}},[_c('b-form-select',{attrs:{"id":"input-regime","options":_vm.regimes,"value-field":"id","text-field":"name","state":_vm.validateState('period_type'),"aria-describedby":"input-regime-feedback","size":"sm"},on:{"change":() => {
          _vm.period.start_date = null;
          _vm.period.end_date = null;
          _vm.period.weeks = 0;
        }},model:{value:(_vm.$v.period.period_type.$model),callback:function ($$v) {_vm.$set(_vm.$v.period.period_type, "$model", $$v)},expression:"$v.period.period_type.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-regime-feedback"}},[_vm._v("Debe seleccionar un "+_vm._s(_vm.$getVisibleNames("mesh.periodtype", false, "Régimen"))+".")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":"Inicio","label-for":"input-execution-date","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-datepicker',{attrs:{"id":"input-execution-date","state":_vm.validateState('start_date'),"aria-describedby":"input-execution-date-feedback","date-disabled-fn":_vm.dateDisabled,"size":"sm"},on:{"input":_vm.numberOfWeeks},model:{value:(_vm.$v.period.start_date.$model),callback:function ($$v) {_vm.$set(_vm.$v.period.start_date, "$model", $$v)},expression:"$v.period.start_date.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-execution-date-feedback"}},[(
          _vm.period.end_date == null ||
          new Date(_vm.period.end_date) < new Date(_vm.period.start_date)
        )?_c('div',[_vm._v(" Este campo es obligatorio y no puede ser igual o mayor que la fecha de término. ")]):_vm._e(),(!_vm.validateNoOverlapping(_vm.period.start_date, _vm.period.end_date))?_c('div',[_vm._v(" No puede contener otro periodo dentro del rango asignado. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":"Término","label-for":"input-end-date","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-datepicker',{attrs:{"id":"input-end-date","state":_vm.validateState('end_date'),"aria-describedby":"input-end-date-feedback","date-disabled-fn":_vm.dateDisabled,"size":"sm"},on:{"input":_vm.numberOfWeeks},model:{value:(_vm.$v.period.end_date.$model),callback:function ($$v) {_vm.$set(_vm.$v.period.end_date, "$model", $$v)},expression:"$v.period.end_date.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-end-date-feedback"}},[(
          _vm.period.end_date == null ||
          new Date(_vm.period.end_date) < new Date(_vm.period.start_date)
        )?_c('div',[_vm._v(" Este campo es obligatorio y no puede ser igual o menor que la fecha de inicio. ")]):_vm._e(),(!_vm.validateNoOverlapping(_vm.period.start_date, _vm.period.end_date))?_c('div',[_vm._v(" No puede contener otro periodo dentro del rango asignado. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"label m-0",attrs:{"label-cols":"0","label-cols-sm":"3","label":"Semanas","label-for":"input-weeks"}},[_c('b-form-input',{attrs:{"id":"input-weeks","type":"number","min":"1","state":_vm.validateState('weeks'),"aria-describedby":"input-weeks-feedback","size":"sm"},model:{value:(_vm.$v.period.weeks.$model),callback:function ($$v) {_vm.$set(_vm.$v.period.weeks, "$model", $$v)},expression:"$v.period.weeks.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-weeks-feedback"}},[_vm._v("Este campo debe ser un entero mayor a cero.")])],1),(!isNaN(this.period.id))?_c('b-form-group',{staticClass:"p-0 m-0 mt-2",attrs:{"label-for":"input-modification-date","label-cols":"0","label-cols-sm":"0"}},[_c('ModificationDate',{attrs:{"Information":_vm.period}})],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(this.period.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deletePeriod}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }